












































import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import Component from 'vue-class-component';
import Vue from 'vue';
import { mdiMail, mdiLock, mdiEye, mdiEyeOff } from '@mdi/js';

@Component
export default class PasswordResetForm extends Vue {
  private email: string = '';
  private password: string = '';
  private dialog: boolean = true;
  private showPassword: boolean = false;
  private error: string | null = null;
  private errorCode: string | null = null;
  private mailIcon = mdiMail;
  private lockIcon = mdiLock;
  private eyeIcon = mdiEye;
  private eyeOffIcon = mdiEyeOff;
  private loginActive: boolean = false;
  private registerActive: boolean = false;
  private createErrorCodes: object = {
    'auth/weak-password': 'Das Passwort ist zu schwach. Bitte gib mehr als 6 Zeichen ein',
    'auth/wrong-password': 'Das eingegebene Passwort ist falsch',
    'auth/email-already-in-use': 'Diese E-Mailadressse wird schon verwendet.',
    'auth/invalid-email': 'Diese E-Mailadresse ist nicht gültig',
    'auth/user-not-found': 'Zu dieser E-Mailadresse wurd kein Konto gefunden',
  };
  private emailRules: Array<(v) => boolean | string> = [
    (v) => !!v || 'E-mail is required',
    (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
  ];
  private passwordRules: Array<(v) => boolean | string> = [(v) => !!v || 'Password is required'];
  private valid: boolean = true;

  constructor() {
    super();
  }

  get buttonsDisabled(): boolean {
    return this.email === null || this.password === null;
  }

  private loginClicked() {
    if (!(this.$refs.form as any).validate()) {
      return;
    }

    this.loginActive = true;
    this.error = null;

    const me = this;
    firebase
      .auth()
      .signInWithEmailAndPassword(me.email, me.password)
      .then((user) => {
        me.loginActive = false;
        me.email = '';
        me.password = '';
        me.dialog = false;
        me.error = null;

        if (user === null || user.user === null) {
          return;
        }

        // Load any data assigned to this user
        this.$store.commit('setUser', user.user);
        this.$store.dispatch('loadUserData').then(() => {
          // Redirect to dashboard if currently on home
          if (me.$route.path === '/') {
            me.$router.push('Dashboard');
          }
        });
      })
      .catch((error) => {
        console.log(error);
        // Handle Errors here.
        me.errorCode = error.code;
        me.error = error.message;

        me.loginActive = false;
      });
  }

  private abort() {
    this.$router.push('/');
  }

  private createUserWithEmailAndPassword() {
    const me = this;
    this.registerActive = true;
    firebase
      .auth()
      .createUserWithEmailAndPassword(this.email, this.password)
      .then(
        (credential) => {
          // Create an empty user in firestore
          const userData = {
            currentFight: null,
            fights: [],
            name: 'Neuer Benutzer',
            settings: {},
            state: 'online',
          };

          if (credential.user === null) {
            return;
          }

          this.$store.commit('setUser', credential.user);
          this.$store
            .dispatch('loadUserData')
            .then(() => {
              me.registerActive = false;
              me.dialog = false;
            })
            .catch((error) => {
              console.log(error.code, error.errorMessage);
              me.registerActive = false;
            });
        },
        (error) => {
          me.registerActive = false;

          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;

          console.log(errorCode, errorMessage);

          // Check if we have a message for this error
          if (me.createErrorCodes.hasOwnProperty(errorCode)) {
            me.error = me.createErrorCodes[errorCode];
          } else {
            me.error = errorMessage;
          }
        },
      );
  }
}
